<template>
  <div>
    <v-card :elevation="cardElevation">
      <v-toolbar :elevation="toolBarElevation" :dense="isDense">
        <v-toolbar-title v-if="!hideTitle">
          <v-icon class="mr-2"> {{titleIcon}}</v-icon>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          single-line
          hide-details
          prepend-icon="mdi-magnify"
          class="mr-4"
          style="max-width: 300px"
          v-model="search"
        >
        </v-text-field>
        <v-btn
        :small="isPageBtnSmall"
          class="mr-2"
          @click="paginatePrevious"
          :disabled="pageNumber == 1"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
        small
          @click="paginateNext"
          
          :disabled="
            pageNumber * pageSize > totalCount - ((totalCount - 1) % pageSize)
          "
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </v-toolbar>
      <v-list>
        <v-list-item v-if="getItems.length === 0">
          <v-list-item-content>
            <v-list-item-title> No {{ title }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in getItems"
          :key="index"
          :two-line="twoline"
        >
        <slot name="repeaterItem" :item="item">

        </slot>
        </v-list-item>
          <!-- <v-list-item-content>
            <v-list-item-title>
              
            </v-list-item-title>
            <v-list-item-subtitle>
              
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-group> -->
      </v-list>
    </v-card>
  </div>
</template>

<script>
import * as generalUtitlities from "../../utilities/GeneralUtilities";
export default {
  props: {
    isDense: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },    
    isPageBtnSmall: {
      type: Boolean,
      default: false
    },
    toolBarElevation: {
      type: Number,
      default: 6
    },
    cardElevation: {
      type: Number,
      default: 2
    },
    titleIcon: {
      type: String,
      default: "mdi-rocket",
    },
    title: {
      type: String,
      default: "Selected Permission Groups",
    },
    items: {
      type: Array,
      default: () => [],
    },
    pageSize:{
      type: Number,
      default: 5
    },
    twoline:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getItems() {
      if (this.items) {
        var innerItems = this.items;

        //now search                        

        innerItems = innerItems.filter((x) => Object.entries(x).some(f => f[1] && f[1].toString().toLowerCase().includes(this.search.toLowerCase())));

        this.totalCount = innerItems.length;

        return generalUtitlities.paginate(
          innerItems,
          this.pageSize,
          this.pageNumber
        );
      }
      else{
        return [];
      }
    },
  },
  watch: {
    search(newValue, oldValue) {
      this.pageNumber = 1;      
    },
  },
  methods: {
    paginateNext() {
      this.pageNumber++;
    },
    paginatePrevious() {
      this.pageNumber--;
    },
  },
  data() {
    return {
      search: "",
      pageNumber: 1,      
      totalCount: 0,
    };
  },
};
</script>

<style>
</style>