<template>
  <trev-cool-table-base :isDense="isDense" :hideTitle="hideTitle" :cardTextClasses="cardTextClasses"
    :additionalQueryStrings="additionalQueryStrings" :addNewLink="addNewLink" :classes="classes" :dateEnd="dateEnd"
    :dateStart="dateStart" :defaultPageSize="defaultPageSize" :headers="headers" :hideCSV="hideCSV"
    :showAddLinkAsButton="showAddLinkAsButton" :tableId="tableId" :title="title" :totalRecordCount="totalRecordCount"
    :items="items" :isLoading="isLoading" 
    
    @pagination-updated="updatePagination" @search-updated="updateSearch"
    :debounceTimeInMS="100" @row-clicked="rowClicked" @add-clicked="$emit('add-clicked')" @get-data="getData()"
    :isExpandable="isExpandable" :isSingleExpand="isSingleExpand" :tableKey="tableKey">
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </trev-cool-table-base>
</template>

<script>
import * as utilities from "../../utilities/GeneralUtilities";
import trevCoolTableBase from "./trev-cool-table-base.vue";

export default {
  components: { trevCoolTableBase },
  props: {
    tableKey: {
      type: String,
      default: ''
    },
    isExpandable: {
      type: Boolean,
      default: false
    },
    isSingleExpand: {
      type: Boolean,
      default: false
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    cardTextClasses: {
      type: String,
      default: "",
    },
    defaultPageSize: {
      type: Number,
      default: 10,
    },
    addNewLink: {
      type: Object,
      default: () => { },
    },
    showAddLinkAsButton: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    tableId: {
      type: String,
      default: "",
    },
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Table Title",
    },
    courier: {
      type: Object,
      default: () => null,
    },
    hideCSV: {
      type: Boolean,
      default: false,
    },
    additionalQueryStrings: {
      type: Array,
      default: () => [],
    },
    getDataOnDateChange: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dateStart: {
      handler() {
        if (this.dateStart && utilities.isValidDate(new Date(this.dateStart))) {
          this.innerDateStart = new Date(this.dateStart);
        } else {
          this.innerDateStart = "";
        }

        if (this.getDataOnDateChange) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
      immediate: true,
    },
    dateEnd: {
      handler() {
        if (this.dateEnd && utilities.isValidDate(new Date(this.dateEnd))) {
          this.innerDateEnd = new Date(this.dateEnd);
        } else {
          this.innerDateEnd = "";
        }

        if (this.getDataOnDateChange) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(this.tableId + "-refresh");
  },
  created() {
    this.$eventBus.$on(this.tableId + "-refresh", () => {
      this.getData();
    });

    //this.getData();
  },
  methods: {
    rowExpanded($event) {
      this.$emit('row-expanded', {
        row: $event.row,
        value: $event.value
      });
    },
    rowClicked(row) {
      this.$emit("row-clicked", row);
    },
    updatePagination(paginationData) {
      this.isLoading = true;
      this.courier
        .getData(
          paginationData.pageNumber,
          paginationData.pageSize,
          paginationData.search,
          this.innerDateStart,
          this.innerDateEnd,
          this.additionalQueryStrings
        )
        .then((data) => {
          this.items = data.payload;
          this.totalRecordCount = data.totalCount;
          this.isLoading = false;
          this.$emit("loaded", this.items);
        });
    },
    updateSearch(paginationData) {
      this.isLoading = true;
      this.courier
        .getData(
          paginationData.pageNumber,
          paginationData.pageSize,
          paginationData.search,
          this.innerDateStart,
          this.innerDateEnd,
          this.additionalQueryStrings
        )
        .then((data) => {
          this.items = data.payload;
          this.totalRecordCount = data.totalCount;
          this.isLoading = false;

          this.$emit("loaded", this.items);
        });
    },
    getData() {
      this.isLoading = true;

      this.courier
        .getData(
          this.pageNumber,
          this.pageSize,
          this.search,
          this.innerDateStart,
          this.innerDateEnd,
          this.additionalQueryStrings
        )
        .then((data) => {
          this.items = data.payload;
          this.totalRecordCount = data.totalCount;
          this.isLoading = false;
          this.$eventBus.$emit(this.tableId + "-data-loaded", data);
          this.$emit("loaded", this.items);
        });
    },
  },
  data: function () {
    return {
      totalRecordCount: 0,
      items: [],
      pageNumber: 1,
      pageSize: this.defaultPageSize,
      search: "",
      innerDateStart: "",
      innerDateEnd: "",
      isLoading: false,
    };
  },
};
</script>

<style></style>
