<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <base-card class="mb-4">
          <v-toolbar>
            <v-toolbar-title>
              <v-icon class="mr-2"> mdi-account-details-outline </v-icon>
              {{ user.firstName + " " + user.lastName }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-subtitle v-if="user.isAdmin" class="green--text">
            Is Admin
          </v-card-subtitle>
        </base-card>
        <base-card>
          <v-toolbar>
            <v-toolbar-title> Basic Details </v-toolbar-title>
          </v-toolbar>
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-redhat </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ user.jobTitle }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-email </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ user.emailAddress }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </base-card>
      </v-col>
      <v-col>
        <trev-cool-table showAddLinkAsButton @add-clicked="showAddGroup" ref="tbl"
          :courier="$courier.SystemUsers.withId($route.params.userId).ScopeGroups" :headers="groupHeaders">
          <template #title>
            <v-icon class="mr-2">mdi-account-group</v-icon>
            Groups
          </template>
          <template #item.actions="{ item }">
            <v-btn color="primary" small class="mr-2" @click="isShowingGroup = true; selectedGroup = item;">
              <v-icon class="mr-2">
                mdi-magnify
              </v-icon>
              View
            </v-btn>
            <v-btn color="danger" small @click="deleteGroup(item)">
              <v-icon class="mr-2">
                mdi-delete
              </v-icon>
              Remove
            </v-btn>
          </template>
        </trev-cool-table>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowingGroup" max-width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2">
              mdi-clipboard-account-outline
            </v-icon>
            Group: {{ selectedGroup.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item v-for="scope in selectedGroupScopes" :key="scope.id">
            <v-list-item-title>
              {{ scope.scope }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowingAddGroup" max-width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2">
              mdi-plus
            </v-icon>
            Add Group to this User
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-select clearable item-text="name" item-value="id" multiple v-model="newGroupsToAdd" chips :items="allGroups"
            label="Group">
          </v-select>
          <v-btn color="primary" @click="addGroup">
            <v-icon class="mr-2">
              mdi-floppy
            </v-icon>
            Save group onto user
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrevCoolList from "../../../../components/tables/trev-cool-list.vue";
import TrevCoolTable from '../../../../components/tables/trev-cool-table.vue';
export default {
  components: { TrevCoolList, TrevCoolTable },
  watch: {
    selectedGroup(newOne, oldOne) {
      this.$courier.ScopeGroups.withId(newOne.id).Scopes.getData(1, 9999999, '').then(scopes => {
        if (scopes.payload) {
          this.selectedGroupScopes = scopes.payload;
        }
      })
    },
    "$route.params.userId": {
      immediate: true,
      handler() {
        this.$courier.SystemUsers.getById(this.$route.params.userId).then(
          (x) => {
            this.user = x;
          }
        );
      },
    },
  },
  async created() {
    this.allGroups = (await this.$courier.ScopeGroups.getData(1, 999, '')).payload;
  },
  methods: {
    async deleteGroup(group) {
      this.$courier.ScopeGroups.removeById(group.id);
      this.$refs.tbl.getData();
    },
    async addGroup() {
      if (this.newGroupsToAdd && this.newGroupsToAdd.length > 0) {
        for (const newGroup of this.newGroupsToAdd) {
          await this.$courier.SystemUsers.withId(this.$route.params.userId).ScopeGroups.updateById(newGroup, null, false);
        }

        this.allGroups = (await this.$courier.ScopeGroups.getData(1, 9999, '')).payload;
        this.isShowingAddGroup = false;
        this.$refs.tbl.getData();
      }
    },
    showAddGroup() {
      this.isShowingAddGroup = true;
    }
  },
  data() {
    return {
      isLoadingGroups: false,
      selectedGroup: {},
      selectedGroupScopes: [],
      isShowingGroup: false,
      isShowingAddGroup: false,
      allGroups: [],
      newGroupsToAdd: [],
      user: {},
      groupHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    };
  },
};
</script>

<style></style>