<template>
  <base-card :class="classes">
    <v-toolbar elevation="0" rounded v-if="!hideTitle">
      <v-toolbar-title>
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-expand-transition>
        <span v-if="search && !searching">
          Search:
          <v-chip close class="ml-1" @click:close="search = ''">
            {{ search }}
          </v-chip>
        </span>
      </v-expand-transition>
      <v-expand-transition>
        <span style="width: 350px" v-show="searching">
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
            class="align-center"
            ref="tblSearch"
            @blur="onSearchBlur"
            @keyup.esc="onSearchBlur"
          >
          </v-text-field>
        </span>
      </v-expand-transition>
      <slot name="topBarButton"></slot>
      <v-btn v-if="showSearch" icon @click="clickedSearchButton">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon :to="addNewLink" v-if="addNewLink">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
      <v-btn icon @click="$emit('add-clicked')" v-if="showAddLinkAsButton">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
      <v-menu offset-y v-if="showMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="getData()">
              <v-list-item-content> Refresh Data </v-list-item-content>
              <v-list-item-action>
                <v-icon> mdi-refresh </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="!hideCSV"
              @click="showCSVExport = true"
              :disabled="!items || items.length == 0"
            >
              <v-list-item-content> Export as CSV </v-list-item-content>
              <v-list-item-action>
                <v-icon> mdi-microsoft-excel </v-icon>
              </v-list-item-action>
            </v-list-item>

            <slot name="tablefunctions"> </slot>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
   
    <v-card-text :class="cardTextClasses">
      <v-data-table
        :class="classes"
        :items="items"
        :items-per-page="pageSize"
        :page="pageNumber"
        :item-key="tableKey"
        :loading="isLoading"
        :headers="getHeaders"
        :server-items-length="totalRecordCount"
        @click:row="rowClicked"
        :dense="isDense"
        @update:options="changedOptions"
        :footer-props="{
          showFirstLastPage: true,
        }"
        :show-expand="isExpandable"
        @item-expanded="itemExpanded"
        :single-expand="isSingleExpand"
      >
        <!-- <template :slot="'item.' + col.name" v-for="(col, index) in headers">
hello
        </template> -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="showCSVExport" max-width="400px">
      <v-card>
        <v-card-title> CSV Export Options </v-card-title>
        <v-card-text>
          <v-text-field label="File Name" v-model="csvFileName"> </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="exportAsCSV"> Export </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-card>
</template>

<script>
import * as utilities from "../../utilities/GeneralUtilities";

export default {
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    tableKey: {
      type: String,
      default: ''
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
    cardTextClasses: {
      type: String,
      default: "",
    },
    defaultPageSize: {
      type: Number,
      default: 10,
    },
    addNewLink: {
      type: Object,
      default: () => {},
    },
    showAddLinkAsButton: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Table Title",
    },
    hideCSV: {
      type: Boolean,
      default: false,
    },
    additionalQueryStrings: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalRecordCount: {
      type: Number,
      default: 0,
    },
    debounceTimeInMS: {
      type: Number,
      default: 300,
    },
  },
  computed: {
    getHeaders() {
      this.headers.forEach((x) => {
        x.sortable = false;
      });

      return this.headers;
    },
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        this.pageNumber = 1;
        clearTimeout(this.debounceId);

        this.debounceId = setTimeout(() => {
          this.$emit("search-updated", {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            search: this.search,
          });
        }, this.debounceTimeInMS);
      },
      immediate: false,
    },
  },
  methods: {
    itemExpanded($event){
      this.$emit('row-expanded', {
        row: $event.item,
        value: $event.value
      });
    },
    changedOptions($event) {
      if ($event.itemsPerPage == -1) {
        this.pageSize = this.totalRecordCount;
      } else {
        this.pageSize = $event.itemsPerPage;
      }

      this.pageNumber = $event.page;
      this.$emit("pagination-updated", {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        search: this.search,
      });
    },
    onSearchBlur() {
      this.searching = false;
    },
    getData() {
      this.$emit("get-data");
    },
    exportAsCSV() {
      var heds = this.headers.map((x) => x);
      var firstItem = this.items[0];
      var csvHeaders = [];

      Object.keys(firstItem).forEach((propName) => {
        if (heds.map((x) => x.value).includes(propName)) {
          csvHeaders.push({
            label: heds.filter((x) => x.value == propName)[0].text,
            value: propName,
          });
        }
      });

      var csvContents = utilities.convertJSONtoCSV(csvHeaders, this.items);

      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(csvContents);
      hiddenElement.target = "_blank";
      hiddenElement.download = this.csvFileName + ".csv";
      hiddenElement.click();
      hiddenElement.remove();
      this.showCSVExport = false;
    },
    clickedSearchButton() {
      this.searching = !this.searching;
      this.$nextTick(() => this.$refs.tblSearch.focus());
    },
    rowClicked(row) {
      this.$emit("row-clicked", row);
    },
    // updatedPagination(paginationObject) {
    //   if (paginationObject.itemsPerPage == -1) {
    //     this.pageSize = this.totalRecordCount;
    //   } else {
    //     this.pageSize = paginationObject.itemsPerPage;
    //   }

    //   this.pageNumber = paginationObject.page;
    //   this.$emit("pagination-updated", {
    //     pageNumber: this.pageNumber,
    //     pageSize: this.pageSize,
    //     search: this.search,
    //   });
    // },
  },
  data: function () {
    return {
      csvFileName: "",
      showCSVExport: false,
      searching: false,
      innerDateStart: "",
      innerDateEnd: "",
      hasLoaded: false,
      debounceId: "",
      search: "",
      pageSize: this.defaultPageSize,
      pageNumber: 1,
    };
  },
};
</script>

<style></style>
